<template>
    <LiefengContent>
        <template v-slot:title>随约打卡服务</template>
        <template v-slot:toolsbarLeft>
            <Select
                v-model="region"
                style="width: 120px; margin-right: 5px"
                placeholder="全部区"
                clearable
                @on-change="
                    e => {
                        changeRegion(e, 'streeList')
                    }
                "
            >
                <Option :value="item.orgCode" v-for="(item, index) in regionList" :key="index">{{ item.orgName }}</Option>
            </Select>
            <Select
                v-model="stree"
                style="width: 160px; margin-right: 5px"
                clearable
                placeholder="全部街道/乡镇"
                @on-change="
                    e => {
                        changeStree(e, 'projectList')
                    }
                "
            >
                <Option :value="item.orgCode" v-for="(item, index) in streeList" :key="index">{{ item.orgName }}</Option>
            </Select>
            <Select v-model="orgCode" style="width: 180px; margin-right: 5px" placeholder="全部社区" clearable>
                <Option :value="item.orgCode" v-for="(item, index) in projectList" :key="index">{{ item.orgName + "[" + item.orgCode + "]" }}</Option>
            </Select>
            <Input v-model.trim="keyword" placeholder="关键词" style="width: 160px; margin-right: 5px"></Input>
            <Button
                type="info"
                @click="
                    () => {
                        getSceneData(1)
                    }
                "
            >
                查询
            </Button>
            <Button
                type="default"
                @click="
                    () => {
                        region = stree = orgCode = keyword = ''
                        getSceneData(1)
                    }
                "
            >
                清空条件
            </Button>
        </template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增</Button>
            <Button type="error" @click="deleteData">删除</Button>
            <!-- <Button type="info" @click="importExcelBtn">导入</Button> -->
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="tableColumns"
                :tableData="menuList"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                @tableSelect="onSelect"
            ></LiefengTable>

            <!-- 导入 -->
            <LiefengModal title="导入" width="40%" height="240px" :value="exportExcel.status" @input="excelModalData" class="export-modal">
                <template v-slot:contentarea>
                    <LiefengUploadExcel
                        style="margin: 20px 0"
                        :showDownModal="{ isShow: true, downLink: './excelfiles/15mintesttemplate.xlsx', downName: '十五分钟核酸圈导入模板' }"
                        :action="exportExcel.exportAction"
                        :uploadData="uploadData"
                        :isShow="exportExcel.showExcel"
                        @success="excelSuccess"
                    ></LiefengUploadExcel>
                </template>
            </LiefengModal>

            <LiefengModal class="map" :fullscreen="false" width="80%" height="550px" title="选择经纬度" :value="mapStatus" @input="mapFn">
                <template v-slot:contentarea>
                    <div style="width: 400px; position: absolute; margin: 10px; z-index: 100; display: flex; background: #ffffff; padding: 10px; border-radius: 10px">
                        <Input v-model="keyword" style="width: 100%; margin-right: 5px" placeholder="请输入地名"></Input>
                        <Button type="primary" @click="setPlace(keyword)">查询</Button>
                    </div>
                    <baidu-map
                        :center="pageCenter.lng == null ? { lng: 113.263, lat: 23.115 } : pageCenter"
                        :zoom="zoom"
                        @ready="handler"
                        :ak="$store.state.baiduAk"
                        :clicking="true"
                        @click="clickMap"
                        :scroll-wheel-zoom="true"
                    >
                        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                        <bm-view style="width: 100%; height: 470px; flex: 1"></bm-view>
                    </baidu-map>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="mapFn(false)">取消</Button>
                    <Button type="primary" @click="selectMap">确定</Button>
                </template>
            </LiefengModal>

            <!-- 新增修改弹窗 -->
            <LiefengModal class="map" :fullscreen="true" :title="JSON.stringify(currentFormData) !== '{}' ? '修改' : '新增'" :value="saveStatus" @input="saveStatusFn">
                <template v-slot:contentarea>
                    <div v-if="JSON.stringify(currentFormData) !== '{}'" style="height: 100%; overflow: hidden">
                        <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                            <!-- <FormItem label="ID" v-if="currentFormData.id">
                                <Input disabled v-model.trim="currentFormData.id"></Input>
                            </FormItem> -->
                            <FormItem label="服务事项" prop="name">
                                <Input v-model.trim="currentFormData.name" placeholder="如：社区直播活动支持" maxlength="50"></Input>
                            </FormItem>
                            <FormItem label="服务类型" prop="type">
                                <CheckboxGroup v-model="currentFormData.type">
                                    <Checkbox v-for="(item, index) in selectList" :key="index" :label="item.dictKey">{{ item.dictValue }}</Checkbox>
                                </CheckboxGroup>
                            </FormItem>

                            <FormItem v-if="!mapStatus">
                                <span slot="label" class="validate">经纬度:</span>
                                <Input v-model.trim="currentFormData.lng" placeholder="经度" style="width: 120px; margin-right: 20px"></Input>
                                <Input v-model.trim="currentFormData.lat" placeholder="纬度" style="width: 120px; margin-right: 20px"></Input>
                                <Button @click="mapFn(true)">地图选点</Button>
                            </FormItem>
                            <FormItem label="服务范围">
                                <Select v-model="city2" transfer style="width: 25%" :max-tag-count="3" placeholder="请选择市" multiple filterable @on-change="changeCity2">
                                    <Option :value="item.value" v-for="(item, index) in cityList2" :key="index">{{ item.label }}</Option>
                                </Select>
                                <Select
                                    v-model="region2"
                                    transfer
                                    style="width: 25%"
                                    :max-tag-count="3"
                                    placeholder="请选择区"
                                    :disabled="regionDisabled"
                                    multiple
                                    filterable
                                    @on-change="changeRegion2"
                                >
                                    <Option :value="item.value" v-for="(item, index) in regionList2" :key="index">{{ item.label }}</Option>
                                </Select>
                                <Select
                                    v-model="stree2"
                                    transfer
                                    style="width: 25%"
                                    :max-tag-count="3"
                                    placeholder="请选择街道/乡镇"
                                    :disabled="streeDisabled"
                                    multiple
                                    filterable
                                    @on-change="changeStree2"
                                >
                                    <Option :value="item.value" v-for="(item, index) in streeList2" :key="index">{{ item.label }}</Option>
                                </Select>
                                <Select v-model="project2" transfer style="width: 25%" :max-tag-count="3" placeholder="请选择社区" :disabled="projectDisabled" multiple filterable>
                                    <Option :value="item.value" v-for="(item, index) in projectList2" :key="index">{{ item.label }}</Option>
                                </Select>
                            </FormItem>

                            <FormItem label="培训人数">
                                <Input style="width: 300px" type="Number" v-model.trim="currentFormData.people" placeholder="请填写数字"></Input>
                                <span style="margin-left: 5px">人</span>
                            </FormItem>
                            <FormItem label="解决问题个数">
                                <Input style="width: 300px" type="Number" v-model.trim="currentFormData.problem" placeholder="请填写数字"></Input>
                                <span style="margin-left: 5px">个</span>
                            </FormItem>
                            <FormItem label="服务时间">
                                <DatePicker transfer type="datetime" @on-change="chaneTime"
                format="yyyy-MM-dd HH:mm:ss" v-model="currentFormData.serviceTime" placeholder="请选择服务时间"></DatePicker>
                            </FormItem>
                            <FormItem label="研究解决的具体问题">
                                <Input type="textarea" rows="8" v-model.trim="currentFormData.content" placeholder="请输入500字内描述"></Input>
                            </FormItem>
                            <FormItem label="现场图">
                                <LiefengUpload
                                    ref="LiefengUploadIcon"
                                    v-if="showImage"
                                    accept=".jpg,.png,.gif,.jpeg"
                                    :format="['jpg', 'png', 'jpeg', 'gif']"
                                    :defaultList="currentFormData.img"
                                    :showView="true"
                                    :multiple="true"
                                    :fileLength="1000"
                                ></LiefengUpload>
                            </FormItem>
                        </Form>
                    </div>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="saveStatusFn(false)">取消</Button>
                    <Button type="primary" @click="submit">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUploadExcel from "@/components/LiefengUploadExcel"
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"
import LiefengUpload from "@/components/LiefengUpload"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUploadExcel,
        BaiduMap,
        BmMarker,
        BmView,
        BmNavigation,
        LiefengUpload,
    },
    data() {
        return {
            mapStatus: false,
            zoom: 16,
            pageCenter: {},
            selectedLocation: {},
            BMap: null,
            map: null,
            keyword: "",
            menuList: [],
            selection: [],
            stree: "",
            orgCode: "",
            region: "",
            regionList: [],
            streeList: [],
            projectList: [],
            streeList1: [],
            projectList1: [],
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    type: "selection",
                    width: 60,
                    key: "id",
                    align: "center",
                },
                {
                    title: "服务事项",
                    width: 250,
                    key: "name",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: { color: "blue", cursor: "pointer" },
                                on: {
                                    click: () => {
                                        this.select(params.row.id)
                                    },
                                },
                            },
                            params.row.name
                        )
                    },
                },
                // {
                //     title: "服务范围",
                //     width: 150,
                //     key: "orgcode",
                //     align: "center",
                //     render: (h, params) => {
                //         return h("span", {}, params.row.orginfo && params.row.orginfo.indexOf('undefined') != -1 && JSON.parse(params.row.orginfo) && JSON.parse(params.row.orginfo).projectname ? JSON.parse(params.row.orginfo).projectname : "未绑定")
                //     },
                // },
                {
                    title: "添加人",
                    minWidth: 200,
                    key: "creatorName",
                    align: "center",
                },
                {
                    title: "添加账号",
                    minWidth: 200,
                    key: "creatorAccount",
                    align: "center",
                },
                {
                    title: "添加时间",
                    key: "gmtCreate",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? (params.row.gmtCreate = this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss")) : "")
                    },
                },
            ],
            buttonProps: {
                type: "default",
                size: "small",
            },
            currentFormData: {},
            defaultList: [],
            validateForm: {
                name: [{ required: true, message: "请输入服务事项", trigger: "blur" }],
                type: [{ required: true, type: "array", min: 1, message: "请选择服务类型", trigger: "change" }],
            },
            exportExcel: {
                status: false,
                exportAction: "",
                showExcel: 0,
            },
            showImage: false,

            selectList: [],

            city2: [],
            region2: [],
            stree2: [],
            project2: [],
            cityList2: [],
            regionList2: [],
            streeList2: [],
            projectList2: [],
            regionDisabled: true,
            streeDisabled: true,
            projectDisabled: true,

            saveStatus: false,

            that: this,
        }
    },
    methods: {
        clearAllCity() {
            this.regionList2 = []
            this.streeList2 = []
            this.projectList2 = []
            this.region2 = []
            this.stree2 = []
            this.project2 = []
            this.regionDisabled = true
            this.streeDisabled = true
            this.projectDisabled = true
        },
        // 关闭弹窗
        saveStatusFn(status) {
            this.saveStatus = status
        },
        // 城市选择
        async changeCity2(code) {
            if (code.length >= 2) {
                this.regionList2 = []
                this.streeList2 = []
                this.projectList2 = []
                this.region2 = []
                this.stree2 = []
                this.project2 = []
                this.regionDisabled = true
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.regionDisabled = false
                let arrCode = code[code.length - 1].split("-")
                await this.getPulishData2(arrCode[arrCode.length - 1], "regionList2", "region2")
            }
        },
        // 区选择
        async changeRegion2(code) {
            if (code.length >= 2) {
                this.streeList2 = []
                this.projectList2 = []
                this.stree2 = []
                this.project2 = []
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.streeDisabled = false
                let arrCode = code[code.length - 1].split("-")
                await this.getPulishData2(arrCode[arrCode.length - 1], "streeList2", "stree2")
            }
        },
        // 街道选择
        async changeStree2(code) {
            if (code.length >= 2) {
                this.projectList2 = []
                this.project2 = []
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.projectDisabled = false
                let arrCode = code[code.length - 1].split("-")
                await this.getPulishData2(arrCode[arrCode.length - 1], "projectList2", "project2")
            }
        },
        // 获取发布范围列表
        async getPulishData2(code, list, model) {
            await this.$get("/gateway/org/app/organization/selectOrganizationByOrgCode", {
                orgCode: code,
            }).then(res => {
                if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                            value: item.orgPath.replace(/,/g, "-"),
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                        }
                    })
                    if (res.dataList.length == "1") {
                        this[model] = [res.dataList[0].orgPath]
                    }
                }
            })
        },
        // 图片上传成功
        uploadSuccess(val) {
            this.currentFormData.img = val
        },
        // 点击导入按钮
        importExcelBtn() {
            this.exportExcel.status = true
            this.exportExcel.showExcel++
            //  this.exportExcel.exportAction = "http://localhost:808/api/o2o/test15min/import";
            this.exportExcel.exportAction = window.vue.getProxy()["/gateway"].target + "/apps/o2o/api/o2o/test15min/import"
        },
        // 关闭导入弹窗事件
        excelModalData(status) {
            this.exportExcel.status = status
        },
        //导入成功的回调
        excelSuccess() {
            this.exportExcel.status = false
            this.getSceneData(1)
        },
        // 区
        selectRegion(code) {
            if (code == null || code == "") return
            this.getPulishData(code, "regionList")
        },
        // 区选择
        changeRegion(code, type) {
            // console.log(code,type);
            if (type == "streeList1") {
                this.currentFormData.stree = ""
                this.currentFormData.orgcode = ""
                this.streeList1 = []
                this.projectList1 = []
            } else {
                this.stree = ""
                this.orgCode = ""
                this.streeList = []
                this.projectList = []
            }

            this.getPulishData(code, type)
        },
        // 街道选择
        changeStree(code, type) {
            if (type == "projectList1") {
                this.currentFormData.orgcode = ""
                this.projectList1 = []
            } else {
                this.orgCode = ""
                this.projectList = []
            }
            this.getPulishData(code, type)
        },
        // 获取范围列表
        getPulishData(code, list) {
            this.$Message.destroy()
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            })
                .then(res => {
                    //  console.log(res.dataList,code);
                    this.$Message.destroy()
                    if (res.code == 200) {
                        this[list] = res.dataList
                    }
                })
                .catch(err => {
                    this.$Message.destroy()
                })
        },

        submit() {
            this.currentFormData.img = (() => {
                if (this.$refs.LiefengUploadIcon.uploadList && this.$refs.LiefengUploadIcon.uploadList.length) {
                    let list = []
                    this.$refs.LiefengUploadIcon.uploadList.map(item => {
                        list.push(item.url)
                    })
                    return list && list.length ? list.join(",") : ""
                }
            })()
            this.$refs.form.validate(status => {
                if (status) {
                    if (!this.currentFormData.lng || !this.currentFormData.lat) {
                        this.$Message.error({
                            background: true,
                            content: "经纬度必填",
                        })
                        return
                    }

                    let orginfo = (() => {
                        if (this.project2.length > 0) {
                            return this.project2
                        } else if (this.stree2.length > 0) {
                            return this.stree2
                        } else if (this.region2.length > 0) {
                            return (this.region2 = typeof this.region2 == "string" ? [this.region2] : this.region2)
                        } else {
                            return typeof this.city2 == "string" ? this.city2.split(",") : this.city2
                        }
                    })()

                    var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
                    cFormData.orginfo = JSON.stringify(orginfo)
                    cFormData.config = JSON.stringify({
                        img: this.currentFormData.img && this.currentFormData.img != "" ? this.currentFormData.img : "",
                        type: this.currentFormData.type.join(","),
                        people: this.currentFormData.people,
                        problem: this.currentFormData.problem,
                        serviceTime: this.currentFormData.serviceTime,
                    })
                    delete cFormData.img
                    delete cFormData.gmtCreate
                    delete cFormData.type
                    delete cFormData.people
                    delete cFormData.problem
                    delete cFormData.serviceTime

                    console.log("新增参数", cFormData)
                    this.$post("/gateway/apps/o2o/api/o2o/test15min/save", {
                        ...cFormData,
                        type: "3",
                        oemCode: parent.vue.oemInfo.oemCode,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "保存成功",
                                })
                                this.getSceneData(this.page)
                                this.saveStatusFn(false)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "保存失败，请联系管理员处理",
                            })
                        })
                }
            })
        },

        append(data) {
            this.defaultList = []
            this.currentFormData = {
                id: data.id,
                status: 1,
                img: [],
                type: [],
            }
            this.showImage = false
            this.$nextTick(() => {
                this.showImage = true
            })
            this.city2 = ["44-4401"]
            this.streeList2 = []
            this.projectList2 = []
            this.region2 = []
            this.stree2 = []
            this.project2 = []
            this.regionDisabled = false
            this.streeDisabled = true
            this.projectDisabled = true
            this.getPulishData2("4401", "regionList2", "region2")
            this.saveStatusFn(true)
        },
        getBCTitle(id, obj) {
            if (obj.id == id) {
                this.bcTitle.unshift(obj.title)
                this.getBCTitle(obj.parentId, this.menuList[0])
            } else if (obj.children && obj.children.length > 0) {
                for (let k in obj.children) {
                    this.getBCTitle(id, obj.children[k])
                }
            }
        },
        select(id) {
            try {
                this.showImage = false
                this.$nextTick(() => {
                    this.showImage = true
                })
                if (id) {
                    this.currentFormData = {}
                    this.city2 = []
                    this.clearAllCity()
                    this.$get("/gateway/apps/o2o/api/o2o/test15min/viewById", {
                        id: id,
                        oemCode: parent.vue.oemInfo.oemCode,
                    })
                        .then(async res => {
                            if (res.code == 200 && res.data) {
                                var cFormData = res.data
                                await this.getPulishData2("44", "cityList2", "city2")
                                cFormData.orginfo = cFormData.orginfo.indexOf("undefined") > -1 ? {} : JSON.parse(cFormData.orginfo)
                                if (typeof cFormData.orginfo == "string") {
                                    cFormData.orginfo = [cFormData.orginfo]
                                    console.log("如果为字符串", cFormData.orginfo)
                                }
                                if (cFormData.orginfo) {
                                    if (cFormData.orginfo && cFormData.orginfo.length == 1) {
                                        //回显发布范围
                                        cFormData.orginfo.map(item => {
                                            let arr = []

                                            if (item.indexOf("-") > -1) arr = item.split("-")
                                            else if (item.indexOf(",") > -1) arr = item.split(",")
                                            switch (arr.length) {
                                                case 2:
                                                    this.city2 = [arr[0] + "-" + arr[1]]
                                                    break
                                                case 3:
                                                    this.city2 = arr[0] + "-" + arr[1]
                                                    this.region2 = arr[0] + "-" + arr[1] + "-" + arr[2]
                                                    break
                                                case 4:
                                                    this.city2 = arr[0] + "-" + arr[1]
                                                    this.region2 = arr[0] + "-" + arr[1] + "-" + arr[2]
                                                    this.stree2 = arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]
                                                    break
                                                case 5:
                                                    this.city2 = arr[0] + "-" + arr[1]
                                                    this.region2 = arr[0] + "-" + arr[1] + "-" + arr[2]
                                                    this.stree2 = arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]
                                                    this.project2 = arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3] + "-" + arr[4]
                                                    break
                                            }
                                        })
                                    } else if (cFormData.orginfo && cFormData.orginfo.length > 1) {
                                        cFormData.orginfo.map(item => {
                                            let arr = []
                                            if (item.indexOf("-") > -1) arr = item.split("-")
                                            else if (item.indexOf(",") > -1) arr = item.split(",")
                                            switch (arr.length) {
                                                case 2:
                                                    this.city2.push(item)
                                                    break
                                                case 3:
                                                    this.city2.push(arr[0] + "-" + arr[1])
                                                    this.region2.push(item)
                                                    break
                                                case 4:
                                                    this.city2.push(arr[0] + "-" + arr[1])
                                                    this.region2.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                                                    this.stree2.push(item)
                                                    break
                                                case 5:
                                                    this.city2.push(arr[0] + "-" + arr[1])
                                                    this.region2.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                                                    this.stree2.push(arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3])
                                                    this.project2.push(item)
                                                    break
                                            }
                                        })
                                        this.city2 = Array.from(new Set(this.city2))
                                        this.region2 = Array.from(new Set(this.region2))
                                        this.stree2 = Array.from(new Set(this.stree2))
                                        this.project2 = Array.from(new Set(this.project2))
                                    }
                                }

                                if (cFormData.config && cFormData.config != "") {
                                    let config = JSON.parse(cFormData.config)
                                    let imgs = config.img
                                    if (imgs && imgs != "") {
                                        cFormData.img = imgs.split(",").map(item => {
                                            return {
                                                url: item,
                                                name: "",
                                            }
                                        })
                                    } else {
                                        cFormData.img = []
                                    }
                                    cFormData.type = config.type && config.type != "" ? config.type.split(",") : ""
                                    cFormData.people = config.people
                                    cFormData.problem = config.problem
                                    cFormData.serviceTime = config.serviceTime
                                }
                                if (!cFormData.orginfo || cFormData.orginfo.length == 0 || JSON.stringify(cFormData.orginfo) == "{}") {
                                    this.city2 = []
                                    this.region2 = []
                                    this.stree2 = []
                                    this.project2 = []
                                }
                                this.currentFormData = cFormData
                                this.$forceUpdate()
                                this.saveStatusFn(true)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "获取数据失败，请联系管理员处理",
                            })
                        })
                }
            } catch (err) {
                console.log("err", err)
            }
        },
        onSelect(selection) {
            this.selection = selection
        },
        deleteData() {
            if (this.selection && this.selection.length > 0) {
                // console.log(this.selection.map(item=>{return item.id}).join(","));
                this.$Modal.confirm({
                    title: "删除确认",
                    content: "是否删除选中的数据？",
                    onOk: () => {
                        this.$post("/gateway/apps/o2o/api/o2o/test15min/delete", {
                            id: this.selection
                                .map(item => {
                                    return item.id
                                })
                                .join(","),
                        })
                            .then(res => {
                                if (res.code == 200) {
                                    this.getSceneData(1)
                                } else {
                                    this.$Message.error({
                                        background: true,
                                        content: "删除出错：" + res.desc,
                                    })
                                }
                            })
                            .catch(err => {
                                this.$Message.error({
                                    background: true,
                                    content: "删除出措",
                                })
                            })
                    },
                })
            } else {
                this.$Message.error({
                    background: true,
                    content: "请先选择删除列",
                })
            }
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.getSceneData(obj.page)
        },
        getSceneData(page) {
            this.selection = []
            this.loading = true
            this.$get("/gateway/apps/o2o/api/o2o/test15min/query", {
                page: page,
                size: this.pageSize,
                keyword: this.keyword,
                orgCode: this.orgCode,
                region: this.region,
                stree: this.stree,
                oemCode: parent.vue.oemInfo.oemCode,
                type: 3,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.menuList = res.dataList
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 点击了地图
        clickMap(e) {
            this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
        },
        handler({ BMap, map }) {
            this.BMap = BMap
            this.map = map
        },
        makerCenter(point) {
            if (this.map) {
                this.selectedLocation = point
                this.map.clearOverlays()
                this.map.addOverlay(new this.BMap.Marker(point))
            }
        },
        mapFn(status) {
            this.mapStatus = status
            if (this.currentFormData.lng) {
                this.keyword = ""
                this.pageCenter = { lng: this.currentFormData.lng, lat: this.currentFormData.lat }
                this.makerCenter(this.pageCenter)
            } else if (this.currentFormData.address) {
                this.keyword = this.currentFormData.address
                this.setPlace(this.currentFormData.address)
            }
        },
        selectMap() {
            this.currentFormData.lng = this.selectedLocation.lng
            this.currentFormData.lat = this.selectedLocation.lat
            this.mapFn(false)
        },
        setPlace(myValue) {
            if (!myValue) return
            var that = this
            var local = new this.BMap.LocalSearch(this.map, {
                onSearchComplete: () => {
                    try {
                        var pp = local.getResults().getPoi(0).point
                        that.pageCenter = { lng: pp.lng, lat: pp.lat }
                        that.makerCenter(pp)
                    } catch (e) {}
                },
            })
            local.search(myValue)
        },
        chaneTime(e) {
            this.currentFormData.serviceTime = this.$core.formatDate(new Date(e),'yyyy-MM-dd hh:mm:ss')
        },

        async getDictTye() {
            await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                appCode: "SUIYUE",
                dictType: "O2O_SERVICE_TYPE",
            }).then(res => {
                if (res.code == 200) {
                    this.selectList = res.dataList
                }
            })
        },
    },
    async created() {
        this.getSceneData(1)
        this.getPulishData("4401", "regionList")
        await this.getPulishData2("44", "cityList2", "city2")
        this.getDictTye()
    },
}
</script>

<style lang='less'>
.leftMenu {
    // position: fixed;
    // top: 60px;
    // left: 0px;
    width: calc(100% - 600px);
    // bottom: 0;
    background: #ffffff;
    // overflow: auto;
    float: left;
}
.rightEdit {
    float: left;
    // position: fixed;
    // top: 60px;
    height: 600px;
    width: 600px;
    // right: 0;
    bottom: 0;
    // overflow: auto;
    border-left: #ddd solid 1px;
}
.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
.ivu-breadcrumb {
    background-color: #fff;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
}
.form {
    padding: 10px 30px 10px 10px;
    height: 100%;
    overflow: scroll;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.toolsbarRight {
    display: flex;
    padding-top: 10px;
}
</style>
